import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div className="container">
			<div className="wrapper-404">
				<h1>404</h1>
				<h2>Page Not Found</h2>
				<a href="/" className="button">Back To Home</a>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
